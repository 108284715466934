/*@import "assets/fonts/"Source Sans Pro"/"Source Sans Pro".css";*/
/*@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i&display=swap');*/
@import "assets/fonts/icons/style";
@import "assets/css/animate.css";
@import url("https://fonts.googleapis.com/css?family=Montserrat");

.ant-dropdown,
.ant-form-item,
.ant-select-dropdown,
button,
body {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  letter-spacing: -0.4px;
  font-style: normal;
  font-stretch: normal;
 
}

.title {
  letter-spacing: 0.4px;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.support-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0,0,0,0.14), 0 4px 8px rgba(0,0,0,0.2);
  cursor: pointer;
  outline: none;
  padding: 0;
  -webkit-user-drag: none;
  font-weight: bold;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: white;
}

.support-button img {
  width: 50%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout {
  background-color: #f9fafb;
}

.ant-layout-header {
  background: rgb(255, 255, 255);
  padding: 0 20px;
  border: 1px solid #e8e8e8;
}

.main-wrapper {
  padding: 40px 0 0;
}

.item {
  color: white;
  cursor: pointer;
  height: 200px;
  margin: 20px;
  position: absolute; /* Required by Muuri */
  width: 50%;
}

.muuri-item-dragging {
  z-index: 3; /* Required by Muuri */
}

.muuri-item-releasing {
  z-index: 2; /* Required by Muuri */
}

.muuri-item-hidden {
  z-index: 0; /* Required by Muuri */
}

.box1 {
  background-color: orange; /* Go */
}

.box2 {
  background-color: blue; /* Gators */
}

/* Carousel */

.slick-dots li button:before {
  font-size: 8px !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px !important;
  font-weight: 800 !important;
  opacity: 1 !important;
}

.slick-prev:hover,
.slick-next:hover {
  opacity: 0.7 !important;
}

.slick-prev {
  left: -45px !important;
}

/* MENU */

.sider {
  position: fixed;
  z-index: 1023;
  min-height: 100vh;
  background: #fff;
  padding: 64px 0 0 0;
  left: 0;

  .menu {
    height: 100%;
    border-right: 0;
  }
}
.ant-layout-sider-zero-width-trigger,
.ant-layout-sider-zero-width-trigger:hover {
  background-color: #1bbfbb;
}

.ant-menu {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", serif;
}
.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #343f5c;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #1bbfbb !important;
}

.ant-menu-vertical,
.ant-menu-vertical-left,
.ant-menu-vertical-right,
.ant-menu-inline {
  .ant-menu-item {
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
    color: #7c8595;
    height: 46px;

    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 4px;
    padding-top: 4px;
  }

  .ant-menu-item:after {
    border-right: 0;
  }

  .ant-menu-item:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-right: 10px solid #1bbfbb;
    transform: scaleY(0.0001);
    opacity: 0;
    transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
      -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
      opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
      opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
      -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white;

  font-weight: bold;
  color: #343f5c;
}

.ant-menu-inline,
.ant-menu-inline {
  .ant-menu-selected:before,
  .ant-menu-item-selected:before {
    transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #1bbfbb !important;
}

.ant-menu-item-selected {
  color: #343f5c !important;
  font-weight: 500;
}

/* SPIN*/

.ant-spin-dot i {
  background-color: #1bbfbb !important;
}

.ant-spin-lg .ant-spin-dot {
  font-size: 42px !important;
  width: 42px !important;
  height: 42px !important;
}

/* SELECT */

.ant-select.select-drop > .ant-select-selection {
  border: none !important;
}

/* DROPDOW */

:root .ant-dropdown-trigger > .anticon:not(.anticon-ellipsis),
:root .ant-dropdown-link > .anticon:not(.anticon-ellipsis) {
  font-size: 16px !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e6fff7;
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-item-disabled:hover {
  background-color: #ffffff;
  cursor: default;
}

/* BUTTON*/

.ant-btn-primary.btn-custom-primary {
  background-color: #1bbfbb !important;
  border-color: #1bbfbb !important;
}
.ant-btn.ant-btn-sm > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 5px;
}
.ant-btn {
  i {
    font-size: 16px;
  }
  text-transform: uppercase;
}
a.ant-btn {
  line-height: normal;
}

.card-indicator {
  text-align: center;
  border-radius: 8px;
  min-height: 200px;
}

.btn-details-all {
  border-color: #1bbfbb;
  color: #1bbfbb;
}

.card-sub-title {
  font-weight: 500;
  text-align: center;
  background: white;
}

.card-number h2 {
  font-size: 48px;
  color: #1bbfbb !important;
  margin-top: 5px;
}

::selection {
  background: #1bbfbb !important;
}

.map-date {
  width: 231px;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.4px;
  text-align: center;
  color: #343f5c;
  cursor: pointer;
  float: right;

  .icon-arrow-left,
  .icon-arrow-right {
    letter-spacing: initial;
    /*padding: 20px;*/
    font-size: 22px;
    color: #7c8595;
  }

  .icon-arrow-left {
    float: left;
  }

  .icon-arrow-right {
    float: right;
  }

  span {
    padding: 20px 0;
  }
}

.ant-layout-footer {
  background: #f9fafb;
}

.main {
  padding: 64px 20px 20px 241px;
  background: #f9fafb;

  h1.title {
    font-size: 28px;
    font-weight: bold;
    color: #343f5c;
  }

  .icon-more {
    font-size: 18px;
    color: #1ab5b5;
  }
}

.hidden {
  visibility: hidden;
  display: none;
}

.ant-list {
  font-family: inherit;
  font-variant: inherit;
}
.ant-list-item-meta-title > a {
  color: initial;
}

.ant-list-split {
  .ant-list-item,
  .ant-list-item:last-child {
    border-bottom: 1px solid #e0e3ea;
  }
}

.scheduling-list {
  .ant-list-item-meta {
    flex: 6 1;
  }

  .ant-list-item-meta-avatar {
    font-family: SFProDisplay, -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.5px;
    color: #1bbfbb;
    align-self: center;
  }

  .ant-list-item-meta-title {
    font-size: 17px;
    font-weight: 500;
    line-height: 1.29;
    margin-bottom: 0;
    color: #343f5c;
  }

  .ant-list-item-meta-description {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: #7c8595;

    .laterality {
      border-radius: 8px;
      background-color: #e0e3ea;

      font-size: 14px;
      font-weight: normal;
      letter-spacing: -0.1px;
      color: #7c8595;

      display: inline-block;
      width: 25px;
      height: 16px;
      text-align: center;
    }

    .allergic:before,
    .iot-hard:before {
      margin-left: 10px;
      margin-right: 3px;
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      align-self: center;
    }

    .allergic:before {
      background-color: #f5c123;
    }

    .iot-hard:before {
      background-color: #f52358;
    }
  }

  .ant-list-item {
    padding: 16px 0 18px;
    cursor: pointer;

    .scheduling-anesthetist-avatar {
      width: 30px;
      height: 30px;
      border-radius: 100px;
      background-color: #e0e3ea;
      display: flex;
      align-items: center;

      span {
        font-size: 13px;
        font-weight: bold;
        color: #7c8595;
        margin: auto;
      }
    }
  }
}

.another-modules {
  position: fixed;
  text-align: center;
  bottom: 0;
  width: 205px;
  margin-bottom: 24px;

  a {
    border-color: transparent;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1bbfbb;
    width: 168px;
    height: 47px;
    border-radius: 6px;
    background-color: #f2f4f7;
    padding: 11px 11px 13px;
    box-shadow: none;
  }

  a:hover,
  a:active,
  a:focus {
    border: 1px solid #1bbfbb;
    color: #1bbfbb;
    background-color: #fff;
  }

  img {
    width: 145px;
  }
}

.ant-btn:not(.ant-btn-circle),
.ant-btn-primary:not(.ant-btn-circle),
.ant-btn-secondary:not(.ant-btn-circle) {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.3px;
  line-height: normal;
  padding: 6px 23px;

  border-radius: 15px;
}

.ant-btn.ant-btn-sm:not(.ant-btn-circle),
.ant-btn-primary.ant-btn-sm:not(.ant-btn-circle),
.ant-btn-secondary.ant-btn-sm:not(.ant-btn-circle) {
  padding: 3px 11px;
}

.ant-btn-primary {
  color: #ffffff;
  background-color: #1bbfbb;
}

.ant-btn-secondary {
  border-color: #ddf6f6;
  color: #1bbfbb;
  background-color: #ddf6f6;
}

.add-procedure {
  float: right;
  border: none;
  min-height: 30px;
  border-radius: 15px;
  background-color: #1bbfbb;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #ffffff;
  padding: 7px 24px;
  //  margin-top: 4px
}


.download-pdfs {
  float: left;
  border: none;
  min-height: 30px;
  border-radius: 15px;
  background-color: #1bbfbb;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #ffffff;
  padding: 7px 24px;
  //  margin-top: 4px
}

.download-pdfs-blocked {
  float: left;
  border: none;
  min-height: 30px;
  border-radius: 15px;
  background-color: #F2F2F2;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #242424;
  padding: 7px 24px;
  //  margin-top: 4px
}

.save-exam {
  border: none;
  min-height: 30px;
  border-radius: 15px;
  background-color: #1bbfbb;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #ffffff;
}

.add-exam {
  float: right;
  border: none;
  min-height: 30px;
  border-radius: 15px;
  background-color: #DDF6F6;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #060606;
  padding: 7px 24px;
  //  margin-top: 4px
}

.retry-emails {
  float: right;
  border: none;
  height: 30px;
  border-radius: 15px;
  background-color: #ff4d4f;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #ffffff;
  padding: 7px 24px;
  //  margin-top: 4px
}

.add-procedure.ant-btn-circle:not(.ant-btn-lg) {
  padding: 7px 0;
  width: 30px;
}

.add-procedure.ant-btn-lg {
  padding: 9px 0;
  font-size: 18px;
}

.container-modal {
  min-width: 60% !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active,
.add-procedure:hover,
.add-procedure:focus,
.add-procedure:active {
  background-color: #1bc9c5;
  border-color: #1bc9c5;
  color: #ffffff;
}

.ant-divider {
  background-color: #e0e3ea;
}

.ant-list-item-meta-content {
  align-self: center;
}

/* FORM */

.ant-form-item-label {
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.2;

  label {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: #7c8595;
  }
}

.ant-input,
.ant-time-picker-input,
.ant-select-auto-complete.ant-select .ant-input,
.ant-cascader-picker,
.ant-select .ant-select-selection {
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid #e0e3ea;
}

.ant-cascader-picker {
  .ant-input {
    border: 0;
  }
}

.ant-radio-button-wrapper {
  border: 1px solid #e0e3ea;
  border-left-width: 0;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 6px 0 0 6px;
  border-left: 1px solid #e0e3ea;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 6px 6px 0;
}

.ant-radio-button-wrapper-checked {
  background: #fff;
  border-color: #1bbfbb;
  color: #1bbfbb;
  box-shadow: -1px 0 0 0 #1bbfbb;
  z-index: 1;
}

.ant-radio-button-wrapper-checked:first-child {
  border-color: #1bbfbb;
  box-shadow: none !important;
}

.ant-form-item {
  margin-bottom: 27px;
}

.ant-form-item-with-help {
  margin-bottom: 8px;
}

.form-add-link {
  border: 0;
  color: #1bbfbb;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;

  font-size: 17px;
  font-weight: 500;
  line-height: 0.82;
  letter-spacing: -0.1px;
}

.form-add-link-exams {
  border: 0;
  color: #1bbfbb;
  padding: 0;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;

  font-size: 16px;
  font-weight: 500;
  // line-height: 0.5;
  letter-spacing: -0.1px;
}

.form-add-link:hover {
  color: #3dccc5;
}

.ant-form-item-control {
  line-height: 42.9999px;
}

.autocomplete-patient {
  border: 1px solid #e0e3ea;
  border-radius: 4px;
  background-color: #ffffff;

  li.ant-select-dropdown-menu-item {
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.82;
    letter-spacing: -0.1px;
    color: #343f5c;
    margin: 16px 3px;
  }

  li.ant-select-dropdown-menu-item:first-child {
    margin-top: 20px;
  }

  .ant-select-dropdown-menu-item-group-title {
    display: none;
    color: #ff4d4f !important;
  }

  .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #e0e3ea;
    margin: 0 17px;
  }

  .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
    padding: 0px;
  }

  .ant-select-dropdown-menu-item:hover {
    background-color: #ffffff;
  }
}

.ant-modal-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: -0.4px;
  color: #343f5c;
}

.card-add-residence {
  width: 100%;
  margin-top: 10px;
}

.card-add-residence > .ant-card-body {
  padding: 10px 0px 5px 0px;
}

@keyframes slidein {
  from {
    min-height: 0;
    height: 0;
    font-size: 0;
  }

  to {
    min-height: 54px;
    height: 54px;
    font-size: 14px;
  }
}

.form-list-item.slide-in {
  animation-duration: 0.25s;
  animation-name: slidein;
}

.ant-checkbox-wrapper {
  display: flex;
  align-items: center;

  .ant-checkbox + span {
    line-height: normal;
  }
}

.form-list {

  .form-list-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.1px;
    color: #7c8595;
  }

  .form-list-item {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 54px;
    border-bottom: 1px solid #e0e3ea;

    .laterality {
      margin-right: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #7c8595;
      float: left;
    }

    .form-list-description {
      display: flex;
      align-items: center;
      line-height: normal;
      font-size: 17px;
      font-weight: 500;
      color: #343f5c;
      float: left;
      flex-grow: 2;

      .ant-checkbox-wrapper {
        flex-grow: 2;
      }
    }

    .form-list-del {
      cursor: pointer;
      float: right;
      padding-right: 7px;
    }
  }
}
.form-list-description-multiple-medical-plans {
  display: flex;
  align-items: center;
  line-height: normal;
  color:#7c8595;
  width: 250px;
}
.form-list-item-multiple-medical-plans{
  justify-content: flex-start;
  align-items: center;
  width: 1000px;
  display: flex;
  margin-bottom: 10px;
  color: #7c8595;
}
.form-list-item-multiple-medical-plans-line{
  justify-content: flex-start;
  align-items: center;
  width: 1000px;
  display: flex;
  color: #7c8595;
}
/* MODAL */
.ant-modal-close {
  left: 0;
  right: initial;
}

.ant-modal-title {
  text-align: center;
}

.btn-ok-modal {
  right: 24px;
  position: absolute;
  top: 21px;

  button {
    float: right;
  }
}

.ant-modal-header {
  border: 0;
  padding: 25px 24px;
}

.ant-modal-content,
.ant-modal-header {
  border-radius: 18px;
  background-color: #ffffff;
}

.ant-modal-close {
  display: none;
}

button.modal-close {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  font-weight: 700;
  text-decoration: none;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: rgba(0, 0, 0, 0.45);
  outline: 0;
  padding-top: 20px;
  padding-left: 22px;

  display: block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  width: 50px;
  height: 52px;
  font-size: 18px;
}

/* CHECKBOX */

.ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.ant-checkbox + span {
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.82;
  letter-spacing: -0.1px;
  color: #343f5c;
  vertical-align: middle;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  font-family: "custom-icons" !important;
  content: "\e907";
  color: #ffffff;
  top: 5px;
  left: 3px;
  font-size: 12px;
  border: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* MODAL SCHEDULING VIEW */

.modal-scheduling-view {
  margin-bottom: 29px;

  .modal-scheduling-view-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.1;
    letter-spacing: -0.4px;
    color: #343f5c;
  }

  .modal-scheduling-view-item {
    font-size: 17px;
    font-weight: 500;
    line-height: 1.29;
    letter-spacing: -0.4px;
    color: #343f5c;

    .modal-scheduling-view-item-title + span {
      display: flex;
      align-items: center;
    }

    .modal-scheduling-view-item-title {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.1px;
      color: #7c8595;
    }

    .modal-scheduling-view-item-queue-done + span {
      display: flex;
      align-items: center;
    }

    .modal-scheduling-view-item-queue-done {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.1px;
      color: #1BBFBB;
    }

    .modal-scheduling-view-item-queue-waiting + span {
      display: flex;
      align-items: center;
    }

    .modal-scheduling-view-item-queue-waiting {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.1px;
      color: #F1C40F;
    }
  }

  .modal-scheduling-view-item:nth-child(2) {
    margin-top: 7px;
  }

  .modal-scheduling-view-item {
    margin-top: 17px;
  }
}

.passwordBox {
  max-width: 460px;
  margin: 0 auto;
  padding: 100px 20px 20px 20px;
}

#root {
  height: 100%;
}

.login-form > .ant-card > .ant-card-body {
  padding: 5px 24px !important;
}

.login-page {
  .ant-card-head {
    border: 0;
  }

  .logo-axreg {
    width: 100%;
    max-width: 302px;
  }

  .logo-axreg-invite {
    width: 100%;
    max-width: 280px;
  }

  .axreg-validate-email {
    width: 100%;
    max-width: 380px;
  }

  .ant-card {
    border-radius: 8px;
    box-shadow: 0 4px 14px 1px rgba(0, 0, 0, 0.04);
  }

  .ant-card-head-title {
    font-size: 17px;
    letter-spacing: 0.5px;
    color: #343f5c;
    font-weight: 600;
  }
}

.btn-custom02 {
  background: #c9cbcf61;
}

.modal-procedures-list {
  .ant-list-split .ant-list-item,
  .ant-list-split .ant-list-item:last-child {
    border-bottom: 1px solid #f2f4f7;
  }
}

.ant-notification {
  z-index: 1050;
}

.tab-title-map {
  font-size: 17px;
  padding: 5px;
}

.ant-avatar {
  font-size: 13px;
  font-weight: bold;
  line-height: 30px;

  background-color: #e0e3ea;
  color: #7c8595;
}

.ant-card-bordered {
  border: none !important;
}

.ant-card-wider-padding .ant-card-body {
  padding: 8px 8px !important;
}

.ant-card {
  border-radius: 8px !important;
  box-shadow: 0 4px 14px 1px rgba(0, 0, 0, 0.04) !important;
}

.ant-table-thead > tr > th {
  background: #f2f2f2 !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #343f5c !important;
  cursor: default !important;
  font-size: 16px !important;
  font-weight: 700;
}

.edit {
  font-size: 14px !important;
}

.header-list {
  background: rgba(0, 0, 0, 0.02);
  border-width: 0 0 2px 0;
  border-color: #e8e8e8;
  font-weight: 700;
  text-align: center;
  color: #343f5c !important;
}

.ant-collapse {
  border: none;
  border-radius: 8px !important;
  box-shadow: 0 4px 14px 1px rgba(0, 0, 0, 0.04) !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}

.ant-list-split .ant-list-header {
  border-bottom: none !important;
}

p.title-field {
  font-size: 17px !important;
  font-weight: 600;
  color: #343f5c;
}

.select-label > .ant-select-selection.ant-select-selection--single {
  border: none !important;
  font-size: 17px !important;
  margin-left: -10px !important;
  font-weight: 600;
  color: #343f5c;
  cursor: default !important;
}

.select-label
  > .ant-select-selection.ant-select-selection--single
  > .ant-select-arrow {
  display: none !important;
}

.ant-list-header,
.ant-list-footer {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #e8e8e847 !important;
}

.col-margin-check {
  margin-bottom: 15px !important;
}

.tag-custom-round {
  border-radius: 15px !important;
  color: rgba(0, 0, 0, 0.65);
}

.card-import .ant-card-body {
  padding: 0px !important;
}

.ant-card-extra .title-switch {
  text-align: right !important;
}

.float-btn {
  position: fixed;
  bottom: 10;
  bottom: 30px;
  right: 30px;
  z-index: 50px;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}

.span-institution {
  float: left;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 285px;
}

.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  color: #1bbfbb !important;
  font-size: 15px !important;
  //background: #e5e5e5 !important;
}

.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters:hover
  .anticon-filter:hover,
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters:hover
  .ant-table-filter-icon:hover {
  //background: #dadada !important;
}

.container-list {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 8px;
  height: 300px;
  margin-top: 10px;
}

.ant-select-dropdown-menu-item-group-title {
  color: #ff4d4f !important;
}

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1024;
}

.text-center {
  text-align: center !important;
}

.table-wrapper {
  width: 100%;
  overflow: auto;
}

table.tb-custom {
  position: relative;
  border: 1px solid #ddd;
  border-collapse: collapse;
  color: #343f5c;
  width: 100%;
}

table.tb-custom td {
  white-space: nowrap;
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.table-title {
  background-color: #1bbfbb;
  color: white;
}

.table-title td:nth-child(1) {
  background: red;
  color: white;
  font-weight: 600;
}

.table-title > .td-not-selected {
  background-color: #1bbfbb;
  color: white;
  text-decoration: none;
}

.td-not-selected {
  background: #80808057;
  color: gray;
  text-decoration: line-through;
}

.td-title {
  color: #343f5c !important;
}

.ant-avatar-string {
  position: unset !important;
}

.ant-card-head-title .logo {
  white-space: normal !important;
}

table.tb-custom th {
  background-color: #eee;
  position: sticky;
  top: -1px;
  z-index: 2;

  white-space: nowrap;
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;

  &:first-of-type {
    left: 0;
    z-index: 3;
  }
}

table.tb-custom tbody tr td:first-of-type {
  background-color: #eee;
  position: sticky;
  left: -1px;
  text-align: left;
}

.ant-btn-link {
  padding: 0 !important;
  margin: 0;
  border: 0;
  width: 14px;
}

.ant-table-footer {
  background: #f2f2f2 !important;
}

.ant-table-footer span {
  text-align: left !important;
  display: block;
}

/* Custom timeline*/

.ant-timeline-item-head {
  width: 17px;
  height: 17px;
  margin-top: 25px !important;
  background-color: transparent;
}
.ant-timeline-item-tail {
  top: 0em !important;
}

.ant-timeline-item-last > .ant-timeline-item-tail {
  display: block;
  height: 30px !important;
}

.ant-timeline-item-tail {
  border-left: 4px solid #e0e3ea !important;
}

.icon-timeline-success {
  font-size: 18px !important;
  color: #28b95c !important;
  background: white !important;
  border-radius: 100% !important;
}

.icon-timeline-error {
  font-size: 18px !important;
  color: #df428a !important;
}

.icon-timeline-processing > .ant-badge-status-dot {
  width: 14px !important;
  height: 14px !important;
  left: 1px;
}

.icon-timeline-start {
  font-size: 18px !important;
  color: #e0e3ea !important;
  background: #e0e3ea !important;
  border-radius: 100% !important;
}

.icon-timeline-cancel {
  font-size: 18px !important;
  color: #df428a !important;
  background: white !important;
  border-radius: 100% !important;
}

.span-timeline-is-team-changed {
  background: #f5c123 !important;
  border-radius: 100% !important;
  width: 23px;
  padding: 1px 3px 2px 3px;
}

.icon-timeline-is-team-changed {
  font-size: 18px !important;
   border-radius: 100% !important;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ant-timeline-item-head-green {
  color: #e0e3ea !important;
  border-color: #e0e3ea !important;
  background-color: #e0e3ea !important;
  left: -2px;
}

.ant-list-item-time-line {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 0px 0;
}

.margin-body-time-line {
  margin-left: 10px !important;
  cursor: pointer;
}

.margin-body-time-line.card-surgery:hover {
  background: #d7f1f199 !important;
}

.margin-body-time-line.card-surgery-realtime:hover {
  background: rgba(223, 66, 138, 0.08) !important;
}

.margin-body-time-line.card-pre:hover {
  background: rgba(0, 145, 255, 0.08) !important;
}

.ant-radio-button-wrapper:first-child {
  border-top-left-radius: 64px;
  border-bottom-left-radius: 64px;
}

.ant-radio-button-wrapper:last-child {
  border-top-right-radius: 64px !important;
  border-bottom-right-radius: 64px !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 0px;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 0px;
}

.group-pre {
  background: rgba(0, 145, 255, 0.15) !important;
  border: solid 2px #0091ff !important;
  text-align: center !important;
  color: #343f5c !important;
}

.group-pre:not(.ant-radio-button-wrapper-checked) {
  color: #7c8595 !important;
  border: solid 2px #e0e3ea !important;
  background: #f8fafc !important;
}

.group-surgery {
  background: #d7f1f1 !important;
  border: solid 2px #1bbfbb !important;
  text-align: center !important;
  color: #343f5c !important;
}

.group-surgery:not(.ant-radio-button-wrapper-checked) {
  color: #7c8595 !important;
  border: solid 2px #e0e3ea !important;
  background: #f8fafc !important;
}

.tab-map > .ant-tabs-bar {
  margin: 0px 0 0px 0px;
  border-bottom: none;
}

.ant-tabs-ink-bar {
  background-color: transparent !important;
}

.title-time-line {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: -0.34px;
  color: #7c8595;
}

.card-surgery-realtime {
  margin-left: 0px !important;
  margin-bottom: 15px !important;
  border: solid 1px #fedee6 !important;
}
.card-surgery-realtime > .ant-card-body {
  padding: 10px 24px 24px 24px !important;
}

.scheduling-list .ant-list-item-meta-avatar.pre {
  font-family: SFProDisplay, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.5px;
  color: #0091ff;
  -webkit-align-self: center;
  align-self: center;
}

.scheduling-list .ant-list-item-meta-avatar.realtime {
  font-family: SFProDisplay, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.5px;
  color: #f62e60;
  -webkit-align-self: center;
  align-self: center;
}

.title-surgery-realtime {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.08px;
  color: #7c8595;
}

.ant-badge-status-processing {
  background-color: #f52358 !important;
}

.ant-badge-status-processing::after {
  border: 1px solid #f52358 !important;
}
.ant-badge-status-dot {
  width: 8px !important;
  height: 8px !important;
}

.status-scheduling {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  text-transform: uppercase;
}

span.s-success {
  color: #28b95c;
}
span.s-running {
  color: #f52358;
}
span.s-cancel {
  color: #df428a;
}

.select-institution-group .ant-select-selection--single {
  height: 50px !important;
}

.dropdown-institution-menu {
  min-width: 350px !important;
}

.check-institution:hover {
  background-color: #e6fff7;
}

.scheduling-list .ant-list-split .ant-list-item,
.ant-list-split .ant-list-item:last-child {
  border-bottom: none !important;
}

.list-patients .ant-list-item {
  padding: 10px 5px 12px;
}

.list-patients .ant-list-item:hover {
  background: #f2f2f2 !important;
}

.btn-custom-danger {
  border: none;
  color: #df4279;
  background-color: rgba(223, 66, 138, 0.15);
}

.btn-custom-danger:hover,
.btn-custom-danger:focus,
.btn-custom-danger:active {
  border: none;
  color: #df4279;
  background-color: rgba(223, 66, 138, 0.15);
  opacity: 0.6;
}

.title-Label {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.1px;
  color: #7c8595;
}

.select-institution-group
  > .ant-select-selection.ant-select-selection--single
  > .ant-select-selection__rendered
  > .ant-select-selection__placeholder {
  top: 75% !important;
}

.details-map {
  display: contents;
}

@media only screen and (max-width: 575px) {
  /* For mobile phones: */

  .ant-radio-button-wrapper {
    padding: 0 0px;
  }

  .ant-badge {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .details-map {
    display: none;
  }
}

@media only screen and (max-width: 1224px) {
  .filterProcedures {
    margin-right: 0px;
    padding-bottom: 10px;
  }
  .containterFilterProcedures {
    display: inline-block;
  }

  .map-date {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 1440px) {
  .filterProcedures {
    padding-right: 10px;
    margin-right: 10px;
  }

  .containterFilterProcedures {
    display: flex;
  }

  .sider {
    overflow: hidden !important;
  }
}

@media only screen and (min-width: 1680px) {
}

@media only screen and (min-width: 1920px) {
}

.institution-logo-intranet {
  width: 100px;
  height: 100px;
  line-height: 34px;
  border-radius: 10px;
  border: 2px solid #e0e3ea;
}

.optional {
  color: grey;
  font-weight: normal;
  font-size: small;
  margin-left: 5px;
}

.neutral-alert {
  color: #7c8595;
  background-color: #f2f3f6;
  border-color: transparent;
  border-radius: 8px;
}

.ant-form-item-label label {
  font-weight: normal;
}

.ant-radio-button-wrapper-checked {
  box-shadow: none;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent;
}

.star-radio {
  border: none;
  background-color: transparent;
}

.ant-radio-button-wrapper-checked::before {
  border-color: transparent;
}

.ant-upload-list-item-card-actions{
  position: absolute;
  right: 0;
  opacity: 100;
}
