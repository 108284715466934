@font-face {
  font-family: 'custom-icons';
  src:
    url('assets/fonts/icons/custom-icons.ttf?gjbve9') format('truetype'),
    url('assets/fonts/icons/custom-icons.woff?gjbve9') format('woff'),
    url('assets/fonts/icons/custom-icons.svg?gjbve9#custom-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'custom-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\e908";
}
.icon-close:before {
  content: "\e906";
}
.icon-checked:before {
  content: "\e907";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-up:before {
  content: "\e905";
}
.icon-arrow-right:before {
  content: "\e904";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-dashboard:before {
  content: "\e902";
}
.icon-more:before {
  content: "\e903";
}
